// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-for-doctor-jsx": () => import("./../../../src/pages/for-doctor.jsx" /* webpackChunkName: "component---src-pages-for-doctor-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-app-faq-dark-jsx": () => import("./../../../src/pages/mobile-app/faq/dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-faq-dark-jsx" */),
  "component---src-pages-mobile-app-faq-light-jsx": () => import("./../../../src/pages/mobile-app/faq/light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-faq-light-jsx" */),
  "component---src-pages-mobile-app-privacy-policy-dark-jsx": () => import("./../../../src/pages/mobile-app/privacy-policy/dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-privacy-policy-dark-jsx" */),
  "component---src-pages-mobile-app-privacy-policy-light-jsx": () => import("./../../../src/pages/mobile-app/privacy-policy/light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-privacy-policy-light-jsx" */),
  "component---src-pages-mobile-app-terms-and-conditions-dark-jsx": () => import("./../../../src/pages/mobile-app/terms-and-conditions/dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-terms-and-conditions-dark-jsx" */),
  "component---src-pages-mobile-app-terms-and-conditions-light-jsx": () => import("./../../../src/pages/mobile-app/terms-and-conditions/light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-terms-and-conditions-light-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

